@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

$primary-color: #f2510d;
$secondary-color: #032d6b;
$tertiary-color: #f4f0e2;
$white-color: #ffffff;
$black-color: #000000;
$black-500: #68686f;

* {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: $tertiary-color;
  }
  &::-webkit-scrollbar-thumb {
    background: $primary-color;
  }
}

.scroll-up {
  cursor: pointer;
  border: 1px solid $white-color;
  background-color: $primary-color;
  color: $white-color;
  padding: 12px 13px;
  position: fixed;
  bottom: 60px;
  right: 80px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

@media only screen and (max-width: 576px) {
  .scroll-up {
    bottom: 40px;
    right: 40px;
  }
}

.section-title {
  font-size: 15px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  color: $primary-color;
  padding: 0 40px;
  border-left: 1px solid $primary-color;
  border-right: 1px solid $primary-color;
  text-transform: uppercase;
  &::after {
    background-color: $primary-color;
    content: "";
    position: absolute;
    top: 0;
    left: 10px;
    height: 100%;
    width: 1px;
  }
  &::before {
    background-color: $primary-color;
    content: "";
    position: absolute;
    top: 0;
    right: 10px;
    height: 100%;
    width: 1px;
  }
}
@media only screen and (max-width: 576px) {
  .section-title {
    margin-bottom: 10px;
    text-align: center;
    display: block;
  }
}

.page-top-section {
  background: linear-gradient(to right, #000000e9, #00000000),
    url("./assets/hero-section.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 50vh;
  padding-left: 4vw;
  .page-pagination {
    color: $black-500;
    display: flex;
    gap: 15px;
    .pagination-item {
      letter-spacing: 3px;
      font-size: 15px;
      color: $black-500;
      text-decoration: none;
      text-transform: uppercase;
    }
    .active {
      color: $primary-color;
    }
  }
  .page-title {
    font-size: 52px;
    font-weight: 500;
    color: $white-color;
  }
}

@media only screen and (max-width: 576px) {
  .page-top-section {
    height: 200px;
    .page-title {
      font-size: 42px;
    }
  }
}
