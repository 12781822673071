$primary-color: #f2510d;
$secondary-color: #032d6b;
$tertiary-color: #f4f0e2;
$white-color: #ffffff;
$black-color: #000000;
$black-500: #68686f;

#home {
  .hero-section {
    background: linear-gradient(to bottom, #000000b0, #000000b0),
      url("../../assets/hero-section.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 60px;
    padding: 20vh 0;
    .banner-sub-text {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      padding: 8px 20px;
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
    }
    .banner-title {
      font-size: 56px;
      font-weight: 700;
      line-height: 60px;
      margin: 10px 0 20px;
    }
    .banner-text {
      font-size: 14px;
      .site-name {
        color: $primary-color;
        font-weight: 500;
      }
    }
    .banner-btn {
      display: flex;
      gap: 40px;
      margin: 40px 0 0 0;
      .out-services {
        cursor: pointer;
        text-decoration: none;
        background-color: $primary-color;
        color: $white-color;
        font-size: 16px;
        font-weight: 500;
        padding: 8px 60px;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        &:hover {
          background-color: $white-color;
          color: $primary-color;
        }
      }
      .apply-now {
        cursor: pointer;
        border: 2px solid $primary-color;
        color: $primary-color;
        font-size: 16px;
        font-weight: 500;
        padding: 8px 60px;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        &:hover {
          background-color: $white-color;
          color: $primary-color;
          border: 2px solid $white-color;
        }
      }
    }
  }

  .about-section {
    margin: 80px 0;
    .about-section-title {
      line-height: 56px;
      font-size: 42px;
      font-weight: 800;
      margin: 10px 0 40px;
    }
    .about-section-appeal {
      display: flex;
      gap: 30px;
      .about-section-appeal-left {
        width: 80px;
      }
      .about-section-appeal-Right {
        flex: 1;
        font-size: 26px;
        font-weight: 500;
      }
    }
    .about-section-text {
      color: $black-500;
      font-size: 18px;
      line-height: 30px;
      margin: 20px 0 40px;
    }
    .about-section-btn {
      cursor: pointer;
      background-color: $primary-color;
      color: $white-color;
      text-decoration: none;
      font-size: 16px;
      font-weight: 600;
      padding: 14px 50px;
      display: inline-block;
      border-radius: 6px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      -ms-border-radius: 6px;
      -o-border-radius: 6px;
    }
    .about-illustration {
      z-index: 10;
      width: 100%;
      box-shadow: 20px 20px 0 $primary-color;
      transition-duration: 600ms;
      &:hover {
        box-shadow: 30px 30px 0 $primary-color;
      }
    }
  }

  .benifit-section {
    margin: 80px 0;
    .benifit-section-title {
      line-height: 56px;
      font-size: 42px;
      font-weight: 800;
      margin: 10px 0 40px;
    }
    .benifit-section-text {
      color: $black-500;
      font-size: 18px;
      line-height: 30px;
      margin: 20px 0 40px;
    }
    .benifit-section-appeal {
      font-size: 26px;
      font-weight: 500;
    }
    .benifit-section-list {
      margin: 20px 0 0 0;
      list-style-type: none;
      .benifit-section-listItem {
        margin: 0 0 10px;
        font-size: 16px;
        font-weight: 400;
        color: $black-500;
        position: relative;
        list-style-image: url("../../assets/tick.svg");
        .text {
          transform: translateY(-5px);
          -webkit-transform: translateY(-5px);
          -moz-transform: translateY(-5px);
          -ms-transform: translateY(-5px);
          -o-transform: translateY(-5px);
        }
      }
    }
    .benifit-illustration {
      z-index: 10;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 576px) {
  #home {
    .hero-section {
      .banner-sub-text {
        text-align: center;
        font-size: 16px;
      }
      .banner-title {
        font-size: 42px;
        line-height: 60px;
      }
      .banner-btn {
        display: flex;
        flex-direction: column;
      }
    }

    .about-section {
      .about-section-title {
        text-align: center;
        font-size: 24px;
        line-height: 30px;
      }
      .about-section-appeal {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .about-section-appeal-Right{
          text-align: center;
        }
      }
      .about-section-text {
        text-align: center;
        color: $black-500;
        font-size: 18px;
        line-height: 30px;
        margin: 20px 0 40px;
      }
      .about-section-btn {
        width: 100%;
        text-align: center;
      }
    }

    .benifit-section {
      .benifit-section-title {
        font-size: 24px;
        line-height: 30px;
        margin: 10px 0 20px;
        text-align: center;
      }
      .benifit-section-text {
        text-align: center;
      }
      .benifit-section-appeal {
        text-align: center;
      }
    }
  }
}
