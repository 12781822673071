$primary-color: #f2510d;
$secondary-color: #032d6b;
$tertiary-color: #f4f0e2;
$white-color: #ffffff;
$black-color: #000000;
$black-500: #68686f;

.faq-section {
  .faq-section-title {
    line-height: 56px;
    font-size: 42px;
    font-weight: 800;
    margin: 10px 0 40px;
  }
  .faq-form {
    display: flex;
    margin: 0 0 10px;
    .faq-field {
      background-color: transparent;
      border: 2px solid $primary-color;
      color: $black-color;
      outline: none;
      padding: 10px 20px;
      border-radius: 30px 0 0 30px;
      -webkit-border-radius: 30px 0 0 30px;
      -moz-border-radius: 30px 0 0 30px;
      -ms-border-radius: 30px 0 0 30px;
      -o-border-radius: 30px 0 0 30px;
      &::placeholder {
        color: $black-500;
      }
    }
    .faq-btn {
      background-color: $primary-color;
      color: $white-color;
      font-size: 18px;
      padding: 0 20px;
      border: 0px;
      border-radius: 0 30px 30px 0;
      -webkit-border-radius: 0 30px 30px 0;
      -moz-border-radius: 0 30px 30px 0;
      -ms-border-radius: 0 30px 30px 0;
      -o-border-radius: 0 30px 30px 0;
    }
  }
  .faq-card {
    border: 1px solid #68686f45;
    padding: 30px 40px;
    cursor: pointer;
    margin: 0 0 20px;
    .faq-question {
      display: flex;
      gap: 40px;
      align-items: center;
      justify-content: space-between;
      font-size: 24px;
      font-weight: 600;
      i {
        font-size: 20px;
        font-weight: 900;
      }
    }
    .active {
      color: $primary-color;
    }
    .faq-answer {
      margin: 20px 0 0;
      font-size: 18px;
      font-weight: 400;
      color: $black-500;
    }
  }
}

@media only screen and (max-width: 576px) {
  .faq-section {
    .faq-section-title {
      line-height: 56px;
      font-size: 24px;
      font-weight: 800;
      margin: 10px 0 40px;
    }
    .faq-card {
      padding: 20px;
      .faq-question {
        font-size: 18px;
      }
      .faq-answer {
        font-size: 16px;
      }
    }
  }
}
