$primary-color: #f2510d;
$secondary-color: #032d6b;
$tertiary-color: #f4f0e2;
$white-color: #ffffff;
$black-color: #000000;
$black-500: #68686f;

.features-section {
  background-color: $tertiary-color;
  padding: 60px 0;
  .features-title {
    line-height: 56px;
    font-size: 42px;
    font-weight: 800;
    margin: 10px 0 40px;
    text-align: center;
  }
  .features-card {
    position: relative;
    background-color: $white-color;
    padding: 150px 40px 40px;
    border-left: 10px solid $tertiary-color;
    border-right: 10px solid $tertiary-color;
    border-bottom: 20px solid $tertiary-color;
    .features-card-img {
      background-color: $black-color;
      position: absolute;
      top: 0;
      left: 0;
      width: 120px;
      padding: 20px;
    }
    .features-card-num {
      opacity: 0.3;
      color: $primary-color;
      font-size: 72px;
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .features-card-text {
      font-size: 22px;
      font-weight: 600;
    }
    .features-card-description {
      font-size: 16px;
      font-weight: 400;
      color: $black-500;
      margin: 20px 0 0 0;
    }
    .right-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      background-color: $black-color;
      color: $white-color;
      position: absolute;
      bottom: -20px;
      right: 40px;
      text-decoration: none;
      &:hover {
        background-color: $primary-color;
        color: $white-color;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
    .features-section {
        .features-title {
          font-size: 24px;
          line-height: 30px;
        }
        .features-card {
          position: relative;
          background-color: $white-color;
          padding: 150px 40px 40px;
          border-left: 10px solid $tertiary-color;
          border-right: 10px solid $tertiary-color;
          border-bottom: 20px solid $tertiary-color;
          .features-card-img {
            background-color: $black-color;
            position: absolute;
            top: 0;
            left: 0;
            width: 120px;
            padding: 20px;
          }
          .features-card-num {
            opacity: 0.3;
            color: $primary-color;
            font-size: 72px;
            position: absolute;
            top: 20px;
            right: 20px;
          }
          .features-card-text {
            font-size: 22px;
            font-weight: 600;
          }
          .features-card-description {
            font-size: 16px;
            font-weight: 400;
            color: $black-500;
            margin: 20px 0 0 0;
          }
          .right-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 40px;
            background-color: $black-color;
            color: $white-color;
            position: absolute;
            bottom: -20px;
            right: 40px;
            text-decoration: none;
            &:hover {
              background-color: $primary-color;
              color: $white-color;
            }
          }
        }
      }
}