$primary-color: #f2510d;
$secondary-color: #032d6b;
$tertiary-color: #f4f0e2;
$white-color: #ffffff;
$black-color: #000000;
$black-500: #68686f;

#header {
  background-color: $white-color;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  z-index: 999;
  .hemburger-menu {
    display: none;
  }
  .logo {
    font-size: 28px;
    font-weight: 600;
    display: flex;
    gap: 5px;
    .logo-main {
      color: $primary-color;
    }
  }
  .navigation-list {
    display: flex;
    align-items: center;
    gap: 25px;
    .navigation-item {
      position: relative;
      color: $black-500;
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
      outline: none;
      border-bottom: 2px solid transparent;
      transition-duration: 600ms;
      display: flex;
      gap: 10px;
      align-items: center;
      i {
        font-size: 14px;
      }
      .navigation-submenu {
        display: none;
        flex-direction: column;
        background-color: $white-color;
        position: absolute;
        top: 100%;
        padding-top: 21px;
        .navigation-submenu-item {
          color: $black-500;
          padding: 10px 40px;
          text-decoration: none;
          white-space: nowrap;
          border: none;
          &:hover {
            background-color: $primary-color;
            color: $white-color;
          }
        }
      }
      &:hover {
        color: $primary-color;
        border-bottom: 2px solid $primary-color;
        .navigation-submenu {
          display: flex;
        }
      }
    }
    .active {
      color: $primary-color;
      border-bottom: 2px solid $primary-color;
    }
    .loan-calculator-btn {
      cursor: pointer;
      background-color: $primary-color;
      color: $white-color;
      font-size: 14px;
      padding: 8px 30px;
      margin: 0 0 0 20px;
      border-radius: 6px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      -ms-border-radius: 6px;
      -o-border-radius: 6px;
    }
  }
}


@media only screen and (max-width: 992px) {
  #header {
    .hemburger-menu {
      display: inline-block;
      margin: 0 30px 0 10px;
      i{
        font-size: 28px;
      }
    }
    .logo {
      font-size: 24px;
    }
    .navigation-list {
      display: none;
      align-items: center;
      gap: 25px;
      .navigation-item {
        position: relative;
        color: $black-500;
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        outline: none;
        border-bottom: 2px solid transparent;
        transition-duration: 600ms;
        display: flex;
        gap: 10px;
        align-items: center;
        i {
          font-size: 14px;
        }
        .navigation-submenu {
          display: none;
          flex-direction: column;
          background-color: $white-color;
          position: absolute;
          top: 100%;
          padding-top: 21px;
          .navigation-submenu-item {
            color: $black-500;
            padding: 10px 40px;
            text-decoration: none;
            white-space: nowrap;
            border: none;
            &:hover {
              background-color: $primary-color;
              color: $white-color;
            }
          }
        }
        &:hover {
          color: $primary-color;
          border-bottom: 2px solid $primary-color;
          .navigation-submenu {
            display: flex;
          }
        }
      }
      .active {
        color: $primary-color;
        border-bottom: 2px solid $primary-color;
      }
      .loan-calculator-btn {
        cursor: pointer;
        background-color: $primary-color;
        color: $white-color;
        font-size: 14px;
        padding: 8px 30px;
        margin: 0 0 0 20px;
        border-radius: 6px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
      }
    }
    .sideBar-container{
      background-color: #0000005b;
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      overflow-y: scroll;
      .sideBar{
        width: 80vw;
        height: 100vh;
        background-color: $secondary-color;
        .logo{
          padding: 20px 80px;
        }
        .sideBar-list{
          display: flex;
          flex-direction: column;
          gap: 10px;
          .sideBar-item{
            color: $white-color;
            padding: 5px 40px 10px;
            text-decoration: none;
            border-bottom: 2px solid $white-color;
            .sideBar-submenu{
              display: flex;
              flex-direction: column;
              .sideBar-submenu-item{
                padding: 10px 40px;
                text-decoration: none;
                color: $white-color;
              }
            }
          }
        }
      }
    }
  }
}
