$primary-color: #f2510d;
$secondary-color: #032d6b;
$tertiary-color: #f4f0e2;
$white-color: #ffffff;
$black-color: #000000;
$black-500: #68686f;

.loan-content {
  margin: 80px 0;
  .navigation-menu {
    position: sticky;
    top: 80px;
    background-color: $tertiary-color;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .navigation-item {
      background-color: $white-color;
      color: $black-500;
      font-size: 16px;
      font-weight: 400;
      padding: 10px 50px;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover {
        background-color: $primary-color;
        color: $white-color;
      }
      i {
        font-size: 14px;
      }
    }
    .active {
      background-color: $primary-color;
      color: $white-color;
    }
  }
  .happy-customer-layout {
    margin: 0 0 40px;
    position: relative;
    .happy-customer {
      width: 100%;
    }
    .happy-customer-percent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 300px;
      height: 200px;
      background-color: $primary-color;
      color: $white-color;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
      font-size: 32px;
      font-weight: 600;
      span {
        font-size: 72px;
      }
    }
    &::before {
      background-color: $black-color;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.6;
      z-index: 1;
    }
  }
  .loan-desc {
    font-size: 18px;
    font-weight: 400;
    color: $black-500;
    margin: 0 0 20px;
  }
  .loan-desc-list {
    margin: 20px 0 0 0;
    list-style-type: none;
    .loan-desc-listItem {
      margin: 0 0 10px;
      font-size: 16px;
      font-weight: 400;
      color: $black-500;
      position: relative;
      list-style-image: url("../../assets/tick.svg");
      .text {
        transform: translateY(-5px);
        -webkit-transform: translateY(-5px);
        -moz-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        -o-transform: translateY(-5px);
      }
    }
  }
  .loan-contact-btn {
    background-color: $primary-color;
    color: $white-color;
    padding: 14px 50px;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    margin-top: 40px;
    display: inline-block;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
  }
}

@media only screen and (max-width: 576px) {
  .loan-content {
    margin: 80px 0;
    .happy-customer-layout {
      .happy-customer-percent {
        width: 300px;
        height: 150px;
        font-size: 24px;
        span {
          font-size: 42px;
        }
      }
    }
  }
}
