$primary-color: #f2510d;
$secondary-color: #032d6b;
$tertiary-color: #f4f0e2;
$white-color: #ffffff;
$black-color: #000000;
$black-500: #68686f;

.policy-details {
    margin: 80px 0;
  .policy-desc {
    font-size: 16px;
    color: $black-500;
    font-weight: 400;
    margin: 0 0 20px;
  }
}
