$primary-color: #f2510d;
$secondary-color: #032d6b;
$tertiary-color: #f4f0e2;
$white-color: #ffffff;
$black-color: #000000;
$black-500: #68686f;

.about-page {
  .about-page-section {
    margin: 80px 0;
    .about-page-title {
      line-height: 56px;
      font-size: 42px;
      font-weight: 800;
      margin: 10px 0 40px;
    }
    .about-page-appeal {
      display: flex;
      gap: 30px;
      .about-page-appeal-left {
        width: 80px;
      }
      .about-page-appeal-Right {
        flex: 1;
        font-size: 26px;
        font-weight: 500;
      }
    }
    .about-page-text {
      color: $black-500;
      font-size: 18px;
      line-height: 30px;
      margin: 20px 0 40px;
    }
    .about-illustration {
      z-index: 10;
      width: 100%;
      box-shadow: 20px 20px 0 $primary-color;
      transition-duration: 600ms;
      &:hover {
        box-shadow: 30px 30px 0 $primary-color;
      }
    }
  }
  .offering-section {
    position: relative;
    .offering-section-text {
      color: $black-500;
      font-size: 18px;
      margin: 20px 0 40px;
    }
    .offeringCard {
      box-shadow: 0 0 18px 0 rgba(10, 27, 95, 0.1);
      .offeringCard-details {
        position: relative;
        padding: 20px;
        &::after {
          background-color: $primary-color;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 2px;
          width: 80%;
          content: "";
        }
        .offeringCard-title {
          font-size: 22px;
          font-weight: 500;
        }
        .offeringCard-text {
          font-size: 17px;
          margin: 20px 0;
          color: $black-500;
        }
      }
      .offeringCard-img {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .about-page {
    .about-page-section {
      .about-page-title {
        line-height: 30px;
        font-size: 24px;
      }
      .about-page-appeal {
        display: flex;
        gap: 30px;
        flex-direction: column;
        align-items: center;
        .about-page-appeal-left {
          width: 80px;
        }
        .about-page-appeal-Right {
          flex: 1;
          font-size: 26px;
          font-weight: 500;
        }
      }
      .about-page-text {
        color: $black-500;
        font-size: 18px;
        line-height: 30px;
        margin: 20px 0 40px;
      }
      .about-illustration {
        z-index: 10;
        width: 100%;
        box-shadow: 20px 20px 0 $primary-color;
        transition-duration: 600ms;
        &:hover {
          box-shadow: 30px 30px 0 $primary-color;
        }
      }
    }
    .offering-section {
      position: relative;
      .offering-section-text {
        color: $black-500;
        font-size: 18px;
        margin: 20px 0 40px;
      }
      .offeringCard {
        box-shadow: 0 0 18px 0 rgba(10, 27, 95, 0.1);
        .offeringCard-details {
          position: relative;
          padding: 20px;
          &::after {
            background-color: $primary-color;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 2px;
            width: 80%;
            content: "";
          }
          .offeringCard-title {
            font-size: 22px;
            font-weight: 500;
          }
          .offeringCard-text {
            font-size: 17px;
            margin: 20px 0;
            color: $black-500;
          }
        }
        .offeringCard-img {
          width: 100%;
        }
      }
    }
  }
}
