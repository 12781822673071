$primary-color: #f2510d;
$secondary-color: #032d6b;
$tertiary-color: #f4f0e2;
$white-color: #ffffff;
$black-color: #000000;
$black-500: #68686f;

.contact-details {
  margin: 80px 0;
  .contact-details-info {
    display: flex;
    gap: 30px;
    margin-bottom: 40px;
    .contact-details-info-left {
      color: $primary-color;
      i {
        font-size: 30px;
      }
    }
    .contact-details-info-right {
      .contact-details-info-title {
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
      }
      .contact-details-info-desc {
        font-size: 16px;
        font-weight: 400;
        color: $black-500;
        margin: 5px 0 0;
      }
    }
  }
  .get-in-touch {
    line-height: 56px;
    font-size: 42px;
    font-weight: 800;
  }
  .contact-desc {
    color: $black-500;
    font-size: 18px;
    font-weight: 400;
    margin: 20px 0 60px;
  }
  .contact-us-form {
    padding: 40px;
    background-color: $primary-color;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .input-field {
      border: none;
      background-color: $white-color;
      box-shadow: none;
      outline: none;
      padding: 8px 20px;
      &::placeholder {
        color: #68686faf;
        font-weight: 400;
      }
    }
    .contact-btn {
      background-color: $white-color;
      color: $primary-color;
      padding: 15px;
      text-align: center;
      font-size: 18px;
      font-weight: 800;
      border: none;
      margin: 10px 0 0 0;
    }
  }
}
