$primary-color: #f2510d;
$secondary-color: #032d6b;
$tertiary-color: #f4f0e2;
$white-color: #ffffff;
$black-color: #000000;
$black-500: #68686f;

.loan-apply-container {
  background-color: #00000069;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  .loan-apply {
    position: relative;
    background-color: $secondary-color;
    color: $white-color;
    width: 60%;
    height: 90vh;
    padding: 40px 60px;
    overflow-y: scroll;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    .cross-mark {
      cursor: pointer;
      font-size: 24px;
      position: absolute;
      right: 20px;
      top: 20px;
      color: $white-color;
    }
    .loan-apply-heading {
      font-size: 32px;
      font-weight: 600;
      margin: 0 0 40px;
    }
    .field-group {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 0 0 30px;
      .field-label {
        font-size: 18px;
        font-weight: 500;
        span {
          color: #ff0000;
        }
      }
      .field-input {
        height: 60px;
        width: 100%;
        padding: 15px 20px;
        border: none;
        outline: none;
        box-shadow: none;
        font-size: 18px;
        border-radius: 6px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        &::placeholder {
          color: $black-500;
          font-size: 18px;
        }
      }
      .mobile-field {
        background-color: $white-color;
        display: flex;
        border-radius: 6px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        .prefix {
          padding: 0 0 0 10px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $black-500;
        }
      }
      .radio-option {
        display: flex;
        gap: 40px;
        .radio-item {
          font-size: 16px;
          display: flex;
          gap: 10px;
        }
      }
    }
    .loan-apply-form {
      width: 100%;
      border: none;
      box-shadow: none;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      font-weight: 700;
      font-size: 18px;
      color: $primary-color;
    }
  }
}

@media only screen and (max-width: 768px) {
  .loan-apply-container {
    .loan-apply {
      width: 90vw;
      height: 90vh;
      padding: 40px 30px;
      .loan-apply-heading {
        font-size: 24px;
      }
      .field-group {
        margin: 0 0 20px;
        .field-label {
          font-size: 16px;
        }
        .field-input {
          height: 50px;
          padding: 10px 15px;
          font-size: 16px;
          &::placeholder {
            font-size: 16px;
          }
        }
        .mobile-field {
          .prefix {
            height: 50px;
          }
        }
      }
    }
  }
}
