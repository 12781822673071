$primary-color: #f2510d;
$secondary-color: #032d6b;
$tertiary-color: #f4f0e2;
$white-color: #ffffff;
$black-color: #000000;
$black-500: #68686f;

.testimonial-section {
  margin: 80px 0;
  overflow: hidden;
  .testimonial-title {
    line-height: 56px;
    font-size: 42px;
    font-weight: 800;
    margin: 10px 0 40px;
  }
  .testimonial {
    position: relative;
    background-color: $white-color;
    padding: 40px;
    .testimonial-header {
      display: flex;
      gap: 40px;
      padding: 0 0 40px;
      .testimonial-left {
        width: 100px;
        position: relative;
        &::before {
          border: 2px dashed $primary-color;
          content: "";
          position: absolute;
          top: -10px;
          right: -10px;
          height: 100%;
          width: 100%;
          z-index: -1;
        }
        .testimonial-image {
          width: 100%;
        }
      }
      .testimonial-right {
        .user-rating {
          display: flex;
          gap: 4px;
          color: $primary-color;
        }
        .user-name {
          font-size: 20x;
          font-weight: 700;
          margin: 10px 0 0 0;
        }
        .user-designation {
          color: $black-500;
          font-size: 14px;
          font-weight: 400;
          text-transform: uppercase;
        }
      }
    }
    .testimonial-content {
      border-top: 2px solid $tertiary-color;
      padding: 30px 0 0;
      color: $black-500;
      font-size: 18px;
      line-height: 30px;
      .testi-quote {
        color: $primary-color;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 140px;
        opacity: 0.1;
      }
    }
    &:hover {
      .testimonial-content {
        border-top: 2px solid $primary-color;
      }
    }
  }
  .testimonial-highlights {
    background-image: url(../../assets/testimonial-highlights.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: -80px 0 0;
    .highight-data {
      margin: 40px 0 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: center;
      height: 400px;
      color: $white-color;
      .highight-number {
        font-size: 62px;
        font-weight: 800;
      }
      .highight-text {
        padding: 10px 40px;
        font-size: 18px;
        font-weight: 700;
        border: 2px solid $primary-color;
      }
      &:hover {
        .highight-text {
          background-color: $primary-color;
          color: $white-color;
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .testimonial-section {
    margin: 80px 0;
    .testimonial-title {
      font-size: 32px;
      line-height: 30px;
    }
    .testimonial-highlights {
      .highight-data {
        height: 300px;
        .highight-number {
          font-size: 42px;
          font-weight: 800;
        }
        .highight-text {
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .testimonial-section {
    margin: 80px 0;
    .testimonial-title {
      font-size: 24px;
      line-height: 30px;
    }
    .testimonial-highlights {
        margin: -20px 0 0;
        .highight-data {
          height: 150px;
          .highight-number {
            font-size: 32px;
            font-weight: 800;
          }
          .highight-text {
            font-size: 16px;
          }
        }
      }
  }
}
