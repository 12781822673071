$primary-color: #f2510d;
$secondary-color: #032d6b;
$tertiary-color: #f4f0e2;
$white-color: #ffffff;
$black-color: #000000;
$black-500: #68686f;

.get-loan {
  background: linear-gradient(110deg, transparent 50%, $tertiary-color 50%),
    url("../../assets/hero-section.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .get-loan-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 300px;
    .get-loan-pagination {
      display: flex;
      gap: 15px;
      color: $secondary-color;
      .loan-pagination-item {
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
    .get-loan-title {
      color: $primary-color;
      font-weight: 700;
      font-size: 42px;
    }
    .get-loan-btn {
      cursor: pointer;
      background-color: $white-color;
      color: $primary-color;
      font-size: 18px;
      font-weight: 400;
      padding: 16px 40px;
      margin: 30px 0 0 0;
      display: inline-block;
      border-radius: 6px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      -ms-border-radius: 6px;
      -o-border-radius: 6px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .get-loan {
    background: $tertiary-color;
    .get-loan-right {
      .get-loan-pagination {
        gap: 10px;
        .loan-pagination-item {
          font-size: 16px;
        }
      }
      .get-loan-title {
        font-size: 36px;
      }
      .get-loan-btn {
        margin: 30px 0 0 0;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .get-loan {
    background: $tertiary-color;
    .get-loan-right {
      .get-loan-pagination {
        gap: 10px;
        .loan-pagination-item {
          font-size: 14px;
        }
      }
      .get-loan-title {
        font-size: 24px;
      }
      .get-loan-btn {
        padding: 10px 20px;
        margin: 30px 0 0 0;
      }
    }
  }
}
