$primary-color: #f2510d;
$secondary-color: #032d6b;
$tertiary-color: #f4f0e2;
$white-color: #ffffff;
$black-color: #000000;
$black-500: #68686f;

.footer-section {
  background-color: $secondary-color;
  padding: 40px 0 10px;
  .logo {
    color: $white-color;
    font-size: 28px;
    font-weight: 600;
    display: flex;
    gap: 5px;
    .logo-main {
      color: $primary-color;
    }
  }
  .footer-desc {
    color: $tertiary-color;
    margin: 10px 0 30px;
  }
  .footer-detail {
    margin: 0 0 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    color: $tertiary-color;
    i {
      color: $primary-color;
    }
  }
  .social-accounts {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 20px 0 0 0;
    .account {
      color: $primary-color;
      border: 2px solid $primary-color;
      padding: 6px 13px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      &:hover {
        background-color: $white-color;
        color: $primary-color;
        border: 2px solid $white-color;
      }
    }
  }
  .footer-title {
    font-size: 15px;
    font-weight: 500;
    position: relative;
    color: $white-color;
    padding: 0 30px;
    border-left: 2px solid $primary-color;
    &::after {
      background-color: $primary-color;
      content: "";
      position: absolute;
      top: 0;
      left: 10px;
      height: 100%;
      width: 2px;
    }
  }
  .footer-links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 30px 0 0;
    .footer-item {
      font-size: 16px;
      font-weight: 400;
      color: $white-color;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .newsletter-form {
    display: flex;
    margin: 30px 0 40px;
    .newsletter-field {
      background-color: transparent;
      color: $white-color;
      border: none;
      outline: none;
      border-left: 1px solid $white-color;
      border-top: 1px solid $white-color;
      border-bottom: 1px solid $white-color;
      padding: 10px 20px;
      &::placeholder {
        color: $white-color;
      }
    }
    .newsletter-btn {
      background-color: $primary-color;
      color: $white-color;
      font-size: 18px;
      padding: 0 20px;
      border: 0px;
    }
  }
  .copyright-section {
    margin: 60px 0 0;
    padding: 20px 0 0;
    text-align: center;
    color: $white-color;
    border-top: 1px solid $tertiary-color;
  }
}

@media only screen and (max-width: 992px) {
  .footer-section {
    .logo {
      font-size: 24px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .footer-section {
    .footer-links {
      margin: 20px 0 0;
    }
  }
}
